define("shared/components/form-elements/simple-selection-input/simple-selection-input", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/utils", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _utils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="relative {{@customClass}}">
    <div class="selection-input-container w-full" role="button" {{on "click" this.toggleMenu}}>
      <div class="input-text cursor-pointer" type="text">
        {{#if (or this.selectedItem @initialValue)}}
          <span class="input-label simple">{{this.displayLabel}}</span>
        {{else}}
          <span class="input-label">{{@inputLabel}}</span>
        {{/if}}
        <i class="icon-angle-down"/>
      </div>
    </div>
  
    {{#if this.isMenuOpen}}
      <div class="selection-menu z-10" id="selection-menu">
        {{#each this.list as |item|}}
          <span role="button" class="p-3 border-b hover:bg-bp-surface-hovered" {{on "click" (fn this.selectItem item)}}
                data-testing-id="{{@dataTestingPrefix}}{{item.val}}-selection-menu-button">
            {{item.text}}
          </span>
        {{/each}}
      </div>
    {{/if}}
    {{#if (and this.showValidationError (not this.isMenuOpen))}}
      <div class="text-bp-base-brand text-sm mt-0">
        {{t 'llc.inputs.item_list_validation'}}
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "O43p+7xz",
    "block": "[[[10,0],[15,0,[29,[\"relative \",[30,1]]]],[12],[1,\"\\n  \"],[11,0],[24,0,\"selection-input-container w-full\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[30,0,[\"toggleMenu\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"input-text cursor-pointer\"],[14,4,\"text\"],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"selectedItem\"]],[30,2]],null],[[[1,\"        \"],[10,1],[14,0,\"input-label simple\"],[12],[1,[30,0,[\"displayLabel\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"input-label\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[10,\"i\"],[14,0,\"icon-angle-down\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"isMenuOpen\"]],[[[1,\"    \"],[10,0],[14,0,\"selection-menu z-10\"],[14,1,\"selection-menu\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"list\"]]],null]],null],null,[[[1,\"        \"],[11,1],[24,\"role\",\"button\"],[24,0,\"p-3 border-b hover:bg-bp-surface-hovered\"],[16,\"data-testing-id\",[29,[[30,5],[30,4,[\"val\"]],\"-selection-menu-button\"]]],[4,[38,0],[\"click\",[28,[37,5],[[30,0,[\"selectItem\"]],[30,4]],null]],null],[12],[1,\"\\n          \"],[1,[30,4,[\"text\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[4]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,6],[[30,0,[\"showValidationError\"]],[28,[37,7],[[30,0,[\"isMenuOpen\"]]],null]],null],[[[1,\"    \"],[10,0],[14,0,\"text-bp-base-brand text-sm mt-0\"],[12],[1,\"\\n      \"],[1,[28,[35,8],[\"llc.inputs.item_list_validation\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@customClass\",\"@initialValue\",\"@inputLabel\",\"item\",\"@dataTestingPrefix\"],false,[\"on\",\"if\",\"or\",\"each\",\"-track-array\",\"fn\",\"and\",\"not\",\"t\"]]",
    "moduleName": "shared/components/form-elements/simple-selection-input/simple-selection-input.hbs",
    "isStrictMode": false
  });
  let SimpleSelectionInputComponent = _exports.default = (_class = class SimpleSelectionInputComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "openMenuByClick", _descriptor, this);
      _initializerDefineProperty(this, "selectedItem", _descriptor2, this);
      _initializerDefineProperty(this, "validInput", _descriptor3, this);
    }
    get displayLabel() {
      return this.selectedItem?.text || this.args.initialValue || this.args.inputLabel;
    }
    get list() {
      return this.args.list || [];
    }
    get isMenuOpen() {
      return this.openMenuByClick && this.list.length > 0;
    }
    get showValidationError() {
      return this.args.validate && (0, _utils.isBlank)(this.selectedItem) && (0, _utils.isBlank)(this.args.initialValue);
    }
    selectItem(item) {
      this.selectedItem = item;
      this.args.selectAction(item.val);
      this.openMenuByClick = false;
    }
    toggleMenu() {
      this.openMenuByClick = !this.openMenuByClick;
    }
    closeMenu() {
      this.openMenuByClick = false;
      const input = document.querySelectorAll('#llc-selection-input');
      if ((0, _utils.isPresent)(input) && input.length > 0) {
        input[0].blur();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "openMenuByClick", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedItem", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "validInput", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMenu", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeMenu", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeMenu"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SimpleSelectionInputComponent);
});