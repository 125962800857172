define("shared/components/business-guide-shared/offer-question", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="md:max-w-148 md:mx-auto">
    <div class="flex flex-col">
      <div class="text-base text-bp-text-icons-main leading-normal md:leading-tight font-bold {{if @alignTitle @alignTitle 'md:text-center'}}">{{@question}}</div>
      <div class="text-sm md:text-base text-bp-text-icons-subdued leading-tight {{if @alignTitle @alignTitle 'md:text-center'}}">{{@subtitle}}</div>
      <div class="flex flex-col gap-3 md:gap-4 mt-3">
        {{#each @answers as |answer|}}
          <BusinessGuideShared::OfferCard @answer={{answer}}
                                                   @image="/images/business-guide/offer/{{answer}}.svg"
                                                   @selected={{contains answer @selectedAnswers}}
                                                   @dataTestingId="segmentation_offer_{{answer}}"
                                                   @clickAction={{fn @toggleAnswer answer}}
                                                   @includeDescriptions={{@includeDescriptions}}/>
        {{/each}}
        {{#if @includeFreeText}}
          <Input @value={{@freeTextAnswer}} placeholder={{t 'business_guide.offer.free_text_placeholder'}}
                 class="h-16 rounded px-4 bg-bp-surface-main transition duration-200 shadow-bp-sm hover:shadow-bp-md cursor-pointer {{if @isFreeTextAnswerAvailable 'border-2 border-bp-primary-main hover:border-bp-primary-main' 'border border-transparent hover:border-black'}}"/>
        {{/if}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "lxEEVTMM",
    "block": "[[[10,0],[14,0,\"md:max-w-148 md:mx-auto\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"text-base text-bp-text-icons-main leading-normal md:leading-tight font-bold \",[52,[30,1],[30,1],\"md:text-center\"]]]],[12],[1,[30,2]],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"text-sm md:text-base text-bp-text-icons-subdued leading-tight \",[52,[30,1],[30,1],\"md:text-center\"]]]],[12],[1,[30,3]],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-col gap-3 md:gap-4 mt-3\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,4]],null]],null],null,[[[1,\"        \"],[8,[39,3],null,[[\"@answer\",\"@image\",\"@selected\",\"@dataTestingId\",\"@clickAction\",\"@includeDescriptions\"],[[30,5],[29,[\"/images/business-guide/offer/\",[30,5],\".svg\"]],[28,[37,4],[[30,5],[30,6]],null],[29,[\"segmentation_offer_\",[30,5]]],[28,[37,5],[[30,7],[30,5]],null],[30,8]]],null],[1,\"\\n\"]],[5]],null],[41,[30,9],[[[1,\"        \"],[8,[39,6],[[16,\"placeholder\",[28,[37,7],[\"business_guide.offer.free_text_placeholder\"],null]],[16,0,[29,[\"h-16 rounded px-4 bg-bp-surface-main transition duration-200 shadow-bp-sm hover:shadow-bp-md cursor-pointer \",[52,[30,10],\"border-2 border-bp-primary-main hover:border-bp-primary-main\",\"border border-transparent hover:border-black\"]]]]],[[\"@value\"],[[30,11]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@alignTitle\",\"@question\",\"@subtitle\",\"@answers\",\"answer\",\"@selectedAnswers\",\"@toggleAnswer\",\"@includeDescriptions\",\"@includeFreeText\",\"@isFreeTextAnswerAvailable\",\"@freeTextAnswer\"],false,[\"if\",\"each\",\"-track-array\",\"business-guide-shared/offer-card\",\"contains\",\"fn\",\"input\",\"t\"]]",
    "moduleName": "shared/components/business-guide-shared/offer-question.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});