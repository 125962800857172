define("shared/components/global/banner", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="cp-simple-banner w-full shadow-md {{@customClass}}">
    <div class="flex text-md md:text-xl {{this.backgroundColor}} text-white text-center w-full {{if @ctaAction 'py-2' 'md:min-h-10 md:max-h-16  md:h-14 py-1.5 md:py-1.5'}}">
      <div class="flex-grow flex items-center justify-center">
        <div class="flex-row flex items-center justify-center">
          <p class="{{this.textColor}} {{if @ctaAction 'mr-4'}}">{{@title}}</p>
          {{#if @ctaAction}}
            <div class="block md:hidden min-w-max">
              <Buttons::Secondary
                @text={{@ctaText}}
                @size="m"
                @type="primary"
                @action={{@ctaAction}}
                @dataTestingId={{@ctaDataTestingId}}/>
            </div>
            <div class="hidden md:block min-w-max">
              <Buttons::Secondary
                @text={{@ctaText}}
                @size="m"
                @type="primary"
                @iconRight="icon-angle-right"
                @action={{@ctaAction}}
                @dataTestingId={{@ctaDataTestingId}}/>
            </div>
          {{/if}}
        </div>
      </div>
      {{#if @onClose}}
        <button data-testing-id={{@closeBannerDataTestingId}} {{on "click" @onClose}} type="button"><i class="banner-close icon-times1"/></button>
      {{/if}}
  
    </div>
  </div>
  
  */
  {
    "id": "bKPb1c8G",
    "block": "[[[10,0],[15,0,[29,[\"cp-simple-banner w-full shadow-md \",[30,1]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"flex text-md md:text-xl \",[30,0,[\"backgroundColor\"]],\" text-white text-center w-full \",[52,[30,2],\"py-2\",\"md:min-h-10 md:max-h-16  md:h-14 py-1.5 md:py-1.5\"]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex-grow flex items-center justify-center\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"flex-row flex items-center justify-center\"],[12],[1,\"\\n        \"],[10,2],[15,0,[29,[[30,0,[\"textColor\"]],\" \",[52,[30,2],\"mr-4\"]]]],[12],[1,[30,3]],[13],[1,\"\\n\"],[41,[30,2],[[[1,\"          \"],[10,0],[14,0,\"block md:hidden min-w-max\"],[12],[1,\"\\n            \"],[8,[39,1],null,[[\"@text\",\"@size\",\"@type\",\"@action\",\"@dataTestingId\"],[[30,4],\"m\",\"primary\",[30,2],[30,5]]],null],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"hidden md:block min-w-max\"],[12],[1,\"\\n            \"],[8,[39,1],null,[[\"@text\",\"@size\",\"@type\",\"@iconRight\",\"@action\",\"@dataTestingId\"],[[30,4],\"m\",\"primary\",\"icon-angle-right\",[30,2],[30,5]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,6],[[[1,\"      \"],[11,\"button\"],[16,\"data-testing-id\",[30,7]],[24,4,\"button\"],[4,[38,2],[\"click\",[30,6]],null],[12],[10,\"i\"],[14,0,\"banner-close icon-times1\"],[12],[13],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@customClass\",\"@ctaAction\",\"@title\",\"@ctaText\",\"@ctaDataTestingId\",\"@onClose\",\"@closeBannerDataTestingId\"],false,[\"if\",\"buttons/secondary\",\"on\"]]",
    "moduleName": "shared/components/global/banner.hbs",
    "isStrictMode": false
  });
  class BannerComponent extends _component2.default {
    get backgroundColor() {
      return this.args.backgroundColor ?? 'bg-us-banner';
    }
    get textColor() {
      return this.args.textColor ?? 'text-bp-text-icons-onDark';
    }
  }
  _exports.default = BannerComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BannerComponent);
});